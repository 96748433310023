import gql from "graphql-tag";
import { PartnerWebsiteValidation, PartnerWebsiteValidationVariables } from "./__generated__/PartnerWebsiteValidation";
import { useApolloClient } from "@apollo/client";

const WebsiteValidationQuery = gql`
  query PartnerWebsiteValidation($website: String!) {
    partnerWebsiteValidation(website: $website) {
      isValid
    }
  }
`;

export const useWebsiteValidation = () => {
  const client = useApolloClient();
  return async (variables: { website: string }) => {
    const result = await client.query<
      PartnerWebsiteValidation,
      PartnerWebsiteValidationVariables
    >({
      query: WebsiteValidationQuery,
      variables,
    });
    return {
      ...result,
      isValid: result.data.partnerWebsiteValidation.isValid,
    };
  };
};
