import { useUserToken } from "@igloo-be-omnipartners/hooks";
import { Exact } from "@royalcanin-be-partner-portal/ui-kit";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { PartnerFullInput } from "../../../../../__generated__/globalTypes";
import { useLocale } from "../locale";
import {
  PartnerUpdate,
  PartnerUpdateVariables,
} from "./__generated__/PartnerUpdate";
import { PartnerDetailsQuery } from "./usePartnerDetails";

const PartnerUpdateMutation = gql`
  mutation PartnerUpdate($token: String!, $input: PartnerFullInput!) {
    partnerUpdate(token: $token, input: $input) {
      error {
        code
        message
        validationErrors {
          field
          errors {
            validator
            message
          }
        }
      }
    }
  }
`;

export const usePartnerUpdate = () => {
  const locale = useLocale();
  const token = useUserToken();
  const [updatePartner, result] = useMutation<
    PartnerUpdate,
    PartnerUpdateVariables
  >(PartnerUpdateMutation);

  return {
    ...result,
    error:
      result.error ||
      (result.data &&
        result.data.partnerUpdate &&
        result.data.partnerUpdate.error),
    updatePartner: async (
      input: PartnerFullInput | Exact<PartnerFullInput>,
    ) => {
      return updatePartner({
        refetchQueries: [
          {
            query: PartnerDetailsQuery,
            variables: {
              extId: input.extId,
              locale,
            },
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          token,
          input,
        },
      });
    },
  };
};
