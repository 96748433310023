import gql from "graphql-tag";
import { useUserToken } from "@igloo-be-omnipartners/hooks";
import { useMutation } from "@apollo/client";
import {
  UploadTokenPartner,
  UploadTokenPartnerVariables,
} from "./__generated__/UploadTokenPartner";
import {
  UploadTokenLitter,
  UploadTokenLitterVariables,
} from "./__generated__/UploadTokenLitter";

const UploadTokenFragment = gql`
  fragment UploadTokenFragment on AmagingPublicOptions {
    token
    url
    urlPreview
  }
`;

export const UploadTokenPartnerQuery = gql`
  mutation UploadTokenPartner($token: String!, $imageIndex: Float!) {
    uploadTokenPartner(token: $token, imageIndex: $imageIndex) {
      ...UploadTokenFragment
    }
  }
  ${UploadTokenFragment}
`;

export const useAmagingUploadTokenForPartner = () => {
  const token = useUserToken();
  const [getAmagingUploadToken, result] = useMutation<
    UploadTokenPartner,
    UploadTokenPartnerVariables
  >(UploadTokenPartnerQuery);

  return {
    ...result,
    getAmagingUploadToken: async (imageIndex: number) => {
      const res = await getAmagingUploadToken({
        variables: {
          token,
          imageIndex,
        },
      });
      return {
        ...res,
        token: res.data?.uploadTokenPartner
      }
    },
  };
};

export const UploadTokenLitterQuery = gql`
  mutation UploadTokenLitter($token: String!, $litterId: String!, $imageIndex: Float!) {
    uploadTokenLitter(
      token: $token
      litterId: $litterId
      imageIndex: $imageIndex
    ) {
      ...UploadTokenFragment
    }
  }
  ${UploadTokenFragment}
`;

export const useAmagingUploadTokenForLitter = () => {
  const token = useUserToken();
  const [getAmagingUploadToken, result] = useMutation<
    UploadTokenLitter,
    UploadTokenLitterVariables
  >(UploadTokenLitterQuery);

  return {
    ...result,
    getAmagingUploadToken: async (litterId: number | string, imageIndex: number) => {
      const res = await getAmagingUploadToken({
        variables: {
          token,
          litterId: litterId.toString(),
          imageIndex,
        },
      });
      return {
        ...res,
        token: res.data?.uploadTokenLitter
      }
    },
  };
};
