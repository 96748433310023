import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLocale } from "../locale";
import {
  partnerFullDetails,
  partnerFullDetailsVariables,
} from "./__generated__/partnerFullDetails";
import { useUserRole } from "./useUserRole";
import {
  partnerFromPuppyKitten,
  partnerFromPuppyKittenVariables,
} from "./__generated__/partnerFromPuppyKitten";

export const PartnerDetailsQuery = gql`
  query partnerFullDetails($extId: String!, $locale: String!) {
    partnerFullDetails(partner_ext_id: $extId, locale: $locale) {
      extId
      lat
      lng
      name
      type
      partnerGroups
      email
      phone
      fax
      address {
        street1
        streetnum
        postalCode
        city
        country
      }
      billing {
        street1
        streetnum
        postalCode
        city
        country
      }
      hasLogo
      logo
      logoSmall
      logoMedium
      logoLarge
      vat
      pictures {
        id
        content
      }
      openingHours {
        day
        dayIndex
        periods {
          from
          to
        }
      }
      website
      facebook
      twitter
      instagram
      youtube
      descriptionGeneric
      description
      descriptionTranslations {
        language
        value
      }
      salesrep
      species
      isConv
      primaryContact {
        firstname
        lastname
      }
      HKNumber
      occasionalBreeder
      publicAddressDisplayPreference
    }
  }
`;

export const usePartnerDetails = ({ extId }: { extId: string }) => {
  const locale = useLocale();
  const { data, ...res } = useQuery<
    partnerFullDetails,
    partnerFullDetailsVariables
  >(PartnerDetailsQuery, {
    variables: {
      extId,
      locale,
    },
    skip: !extId,
  });

  return {
    ...res,
    data: (data && data.partnerFullDetails) || [],
  };
};

export const useCurrentPartnerDetails = () => {
  const { data: userRoleData, loading: userRoleLoading } = useUserRole();
  const { data: partners, loading: partnerDetailsLoading } = usePartnerDetails({
    extId: (userRoleData && userRoleData.partnerRelation.extId) || "",
  });
  const loading = userRoleLoading || partnerDetailsLoading;
  const partner = partners && partners[0];
  const delegateName =
    partner &&
    partner.salesrep &&
    partner.salesrep.replace(/@.+/, "").replace(".", " ");
  return {
    loading,
    data: partner
      ? {
          ...partner,
          delegateName,
        }
      : undefined,
  };
};

export const PartnerFromPuppyKittenQuery = gql`
  query partnerFromPuppyKitten($extId: String!) {
    partnerFromPuppyKitten(partner_ext_id: $extId) {
      extId
      region {
        reference
        isFlanders
      }
      isHKNumberValid
    }
  }
`;

export const usePartnerFromPuppyKitten = ({ extId }: { extId: string }) => {
  const { data, ...res } = useQuery<
    partnerFromPuppyKitten,
    partnerFromPuppyKittenVariables
  >(PartnerFromPuppyKittenQuery, {
    variables: {
      extId,
    },
    skip: !extId,
  });

  return {
    ...res,
    data: data && data.partnerFromPuppyKitten,
  };
};
