import { useMetadataCountries } from "@igloo-be-omnipartners/hooks";
import {
  CardBilling,
  CardCoordinates,
  CardDelegate,
  CardDesc,
  CardIdentity,
  CardLocation,
  CardNetworks,
  CardOpeningHours,
  CardPictures,
  CardsRow,
  Wrapper,
  ColFlex,
  CardBreederInfos,
} from "@royalcanin-be-partner-portal/ui-kit";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "react-styled-flexboxgrid";
import { Layout } from "../components/Layout";
import {
  useCurrentPartnerDetails,
  usePartnerFromPuppyKitten,
} from "../lib/hooks/usePartnerDetails";
import { usePartnerUpdate } from "../lib/hooks/usePartnerUpdate";
import { useLocale } from "../lib/locale";
import { useAmagingUploadTokenForPartner } from "../lib/hooks/useAmagingUploadToken";
import { PartnerPictureInput } from "../../../../__generated__/globalTypes";
import { CheckPermission } from "../components/CheckPermission";
import { withPage } from "../lib/withPage";
import { useUserRole } from "../lib/hooks/useUserRole";
import { RouteComponentProps } from "@reach/router";
import { parse } from "query-string";
import { useWebsiteValidation } from "../lib/hooks/useWebsiteValidation";

const Information = ({ location }: RouteComponentProps) => {
  const locale = useLocale();
  const { data: partner, loading } = useCurrentPartnerDetails();
  const { items: countries } = useMetadataCountries({
    lang: locale,
    skip: loading,
  });
  const phoneCountries = ["BE", "LU", "FR", "NL"];
  const countriesFiltered = countries.filter(
    ({ value }) => phoneCountries.indexOf(value) >= 0,
  );
  const { updatePartner } = usePartnerUpdate();
  const { getAmagingUploadToken } = useAmagingUploadTokenForPartner();
  const { data: userRole } = useUserRole();
  const hashKeys = Object.keys(parse(location?.hash || ""));
  const { data: partnerPuppyKitten, loading: partnerPuppyKittenLoading } =
    usePartnerFromPuppyKitten({ extId: partner?.extId || "" });
  const websiteValidation = useWebsiteValidation();

  return (
    <Layout>
      <CheckPermission permissions={"information"} redirect>
        <Wrapper>
          <Row>
            <ColFlex xs={12} sm={12} md={6} lg={8}>
              <CardIdentity
                loading={loading}
                partner={partner}
                onSubmit={async (values) => {
                  if (!partner) {
                    return;
                  }
                  const result = await updatePartner({
                    extId: partner.extId,
                    name: values.name,
                    logo:
                      partner.logo !== values.logo ? values.logo : undefined,
                  });
                  return result.data?.partnerUpdate.error;
                }}
              />
            </ColFlex>
            <ColFlex xs={false} sm={false} md={6} lg={4}>
              <CardDelegate
                loading={loading}
                title={
                  <FormattedMessage
                    id="informations.delegate.title"
                    defaultMessage="Mon délégué"
                  />
                }
                linkUrl="/contact"
                name={(partner && partner.delegateName) || "-"}
              />
            </ColFlex>
          </Row>
          <CardsRow>
            <Row>
              <ColFlex xs={12} sm={12} md={6} lg={4}>
                <CardCoordinates
                  loading={loading}
                  partner={partner}
                  countries={countriesFiltered}
                  onSubmit={async (values) => {
                    if (!partner) {
                      return;
                    }

                    const result = await updatePartner({
                      extId: partner.extId,
                      address: values.address && {
                        street1: values.address.street1,
                        streetnum: values.address.streetnum,
                        postalCode: values.address.postalCode,
                        city: values.address.city,
                        country: values.address.country,
                      },
                      email: values.email,
                      phone: values.phone,
                      fax: values.fax,
                      // Else Clixray set to null if address change
                      lng: partner.lng,
                      lat: partner.lat,
                      publicAddressDisplayPreference:
                        partner.type === "breeders"
                          ? values.publicAddressDisplayPreference
                          : undefined,
                    });
                    return result.data?.partnerUpdate.error;
                  }}
                  autoOpenForm={hashKeys.indexOf("coordinates") >= 0}
                />
              </ColFlex>
              <ColFlex xs={12} sm={12} md={6} lg={4}>
                <CardBilling
                  loading={loading}
                  partner={partner}
                  countries={countriesFiltered}
                  onSubmit={async (values) => {
                    if (!partner) {
                      return;
                    }

                    const result = await updatePartner({
                      extId: partner.extId,
                      address: values.billing && {
                        street1: values.billing.street1,
                        streetnum: values.billing.streetnum,
                        postalCode: values.billing.postalCode,
                        city: values.billing.city,
                        country: values.billing.country,
                      },
                      vat: values.vat,
                    });
                    return result.data?.partnerUpdate.error;
                  }}
                  editMailTo={
                    userRole?.roleDefinition.billingMailto || undefined
                  }
                />
              </ColFlex>
              <ColFlex xs={12} sm={12} md={12} lg={4}>
                <CardLocation
                  partner={partner}
                  onSubmit={async ({ lat, lng }) => {
                    if (!partner || !lat || !lng) {
                      return;
                    }
                    const result = await updatePartner({
                      extId: partner.extId,
                      lat: lat.toString(),
                      lng: lng.toString(),
                    });
                    return result.data?.partnerUpdate.error;
                  }}
                />
              </ColFlex>
            </Row>
          </CardsRow>
          <Row>
            <ColFlex xs={12}>
              <CardPictures
                loading={loading}
                partner={partner}
                onSubmit={async ({ pictures }) => {
                  if (!partner) {
                    return;
                  }

                  const picturesData = (
                    await Promise.all(
                      pictures.map(async (picture, i) => {
                        if (!picture.file) {
                          return {
                            id: picture.id as number,
                            content: picture.content,
                          };
                        }

                        const { token: amagingUploadToken } =
                          await getAmagingUploadToken(i);

                        if (!amagingUploadToken) {
                          return;
                        }

                        await fetch(amagingUploadToken.url, {
                          method: "post",
                          body: picture.file,
                          headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + amagingUploadToken.token,
                            "Content-Lenght": picture.file.size.toString(),
                            "Content-Type": picture.file.type,
                          },
                        });

                        return {
                          id: -1,
                          content: amagingUploadToken.urlPreview,
                        };
                      }),
                    )
                  ).filter(Boolean) as PartnerPictureInput[];

                  const result = await updatePartner({
                    extId: partner.extId,
                    pictures: picturesData,
                  });
                  return result.data?.partnerUpdate.error;
                }}
              />
            </ColFlex>
          </Row>
          <CardsRow style={{ marginBottom: 0 }}>
            <Row>
              {partner?.type !== "breeders" && (
                <ColFlex xs={12} sm={12} md={6} lg={4}>
                  <CardOpeningHours
                    loading={loading}
                    partner={partner}
                    locale={locale}
                    onSubmit={async (values) => {
                      if (!partner) {
                        return;
                      }
                      const result = await updatePartner({
                        extId: partner.extId,
                        openingHours: values.openingHours,
                      });
                      return result.data?.partnerUpdate.error;
                    }}
                  />
                </ColFlex>
              )}
              {partner?.type === "breeders" && (
                <ColFlex xs={12} sm={12} md={6} lg={4}>
                  <CardBreederInfos
                    loading={loading || partnerPuppyKittenLoading}
                    partner={partner}
                    region={partnerPuppyKitten?.region || undefined}
                    onSubmit={async (values) => {
                      if (!partner) {
                        return;
                      }
                      if (!partner) {
                        return;
                      }

                      const result = await updatePartner({
                        extId: partner.extId,
                        HKNumber: values.HKNumber || "",
                        occasionalBreeder: partnerPuppyKitten?.region
                          ?.isFlanders
                          ? values.occasionalBreeder
                          : undefined,
                      });

                      return result.data?.partnerUpdate.error;
                    }}
                    autoOpenForm={hashKeys.indexOf("breedersinfo") >= 0}
                  />
                </ColFlex>
              )}
              <ColFlex xs={12} sm={12} md={12} lg={4}>
                <CardDesc
                  locale={locale}
                  loading={loading}
                  partner={partner}
                  onSubmit={async (values) => {
                    if (!partner) {
                      return;
                    }

                    const description = values.descriptionTranslations?.find(
                      ({ language }: { language: string }) =>
                        language.toLocaleLowerCase() === locale,
                    );
                    const result = await updatePartner({
                      extId: partner.extId,
                      descriptionTranslations: values.descriptionTranslations,
                      descriptionGeneric:
                        (description && description.value) || undefined,
                    });
                    return result.data?.partnerUpdate.error;
                  }}
                />
              </ColFlex>
              <ColFlex xs={12} sm={12} md={12} lg={4}>
                <CardNetworks
                  loading={loading}
                  partner={partner}
                  onSubmit={async (values) => {
                    if (!partner) {
                      return;
                    }

                    const result = await updatePartner({
                      extId: partner.extId,
                      website: values.website,
                      facebook: values.facebook,
                      twitter: values.twitter,
                      instagram: values.instagram,
                      youtube: values.youtube,
                    });
                    return result.data?.partnerUpdate.error;
                  }}
                  validateWebsite={async (v) => {
                    const { isValid } = await websiteValidation({ website: v });
                    return isValid;
                  }}
                />
              </ColFlex>
            </Row>
          </CardsRow>
        </Wrapper>
      </CheckPermission>
    </Layout>
  );
};

export default withPage(Information);
